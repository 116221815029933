import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet';
import SRTravelFeatures from './SRTravelFeatures';
import BookingOptions from './BookingOptions';
import BestTimeForRentingCar from './BestTimeForRentingCar';
import RentACarComponent from './RentACarComponent';
import FAQsComponent from './FAQsComponent';
import SRCustomForm from '../components/common/SRCustomForm';
import GunturTravelExperience from './GunturTravelExperience';

// Assuming these paths are correct relative to this component file
const imagePaths = [
    require("../assets/img/g_frame_2.png"),
    require("../assets/img/g_frame_3.png")
];

const customImagePaths = {  
    bannerImage: require("../assets/img/Best Car Travels in Guntur_Banner.png") 
};

const BestCarTravelsInGuntur = () => {
    return (
        <>
            <Helmet>
                <title>Best Car Travels in Guntur | SR Car Travels</title>
                <meta name="description" content="SR Car Travels is dedicated to offering a high-quality, stress-free cab service in Guntur, ensuring an experience of outstanding travel satisfaction with our best car travels in Guntur." />
                <link rel="canonical" href="https://srcartravels.in/best-car-travels-in-guntur" />
                <meta name="robots" content="index, follow" />

                <meta property="og:site_name" content="SR Car Travels" />
                <meta property="og:url" content="https://srcartravels.in/" />
                <meta property="og:title" content="Best Car Travels in Guntur | SR Car Travels" />
                <meta property="og:description" content="SR Car Travels is dedicated to offering a high-quality, stress-free cab service in Guntur." />

            </Helmet>
            <SRCustomForm BannerTitle="Best Car Travels in Guntur" BannerImg={customImagePaths.bannerImage}/>
            <GunturTravelExperience />
            <SRTravelFeatures />
            <BookingOptions />
            <BestTimeForRentingCar />
            <RentACarComponent />
            <FAQsComponent />
        </>
    );
};

export default BestCarTravelsInGuntur;
