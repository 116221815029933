import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from "react-helmet";
import "../LandingPages/InsideGuideInNavigatingVijayawada.css";

// Assuming these paths are correct relative to this component file
const imagePaths = {
  bannerImage: require("../assets/img/lp1/l1.png"),
  lp1: require("../assets/img/lp4-1.png"),
  lp2: require("../assets/img/lp4-2.png"),
  lp3: require("../assets/img/lp4-3.png"),
  lpt1: require("../assets/img/lpt4-1.png"),
  lpt2: require("../assets/img/lpt4-2.png"),
  lpt3: require("../assets/img/lpt4-3.png"),
  lpt4: require("../assets/img/lpt4-4.png"), 
  lpt5: require("../assets/img/lpt4-5.png"), 
  lpt6: require("../assets/img/lpt4-6.png"), 
};

// Custom style for the thin line
const thinLineStyle = {
  width: "75%",
  margin: "20px auto", // Combined marginTop and marginBottom
  height: "1px",
  backgroundColor: "#ddd",
};

const data = [
  {
    title:
      "How long does it take to travel from Vijayawada to Hyderabad by cab?",
    content:
      "The travel time from Vijayawada to Hyderabad by cab varies based on several factors, including the chosen route, traffic conditions, and weather. Generally, the journey spans an average duration of 5 to 6 hours.",
  },
  {
    title: "How is the Vijayawada to Hyderabad Car Rental Charged?",
    content:
      "Typically, taxi fares are determined by the distance traveled, including the distance between the pick-up and drop off points.",
  },
  {
    title: "Is it Crucial to Book Cabs in Advance?",
    content:
      "It is highly recommended to book cabs in advance before starting your trip, especially during peak travel seasons. Finding cabs on the spot may become tedious, and the prices could also increase.",
  },
  {
    title: "How to book a One Way Cab from Vijayawada to Hyderabad?",
    content:
      "When booking a cab from Vijayawada to Hyderabad, travelers can choose between one way drop and round trip options.",
  },
  {
    title:
      "What are the Benefits of Making Online Cab/taxi Bookings with SR Car Travels?",
    content:
      "At SR Car Travels, our goal is to elevate your journey into an unforgettable experience. That's why we are dedicated to providing travelers like you with a wide range of benefits, including meticulously sanitized cabs, premium vehicles, adherence to safe driving practices, and access to travel advisory facilities. Moreover, we pride ourselves on offering round the clock reliable customer support and exceptional flexibility in booking arrangements.",
  },
];

const bookingOptions = [
  {
    imgSrc: imagePaths.lpt1,
    title: "Charminar",
    text: (
      <>
        Renowned as an iconic symbol of Hyderabad, Charminar stands as a
        magnificent monument and mosque in the city's heart. It was constructed
        in 1591 by Sultan Muhammad Quli Qutb Shah, this historic structure is
        celebrated for its impressive architecture and profound significance.
      </>
    ),
  },
  {
    imgSrc: imagePaths.lpt2,
    title: "Hussain Sagar Lake",
    text: (
      <span>
        Experience the tranquility of a boat ride on Hussain Sagar Lake, an
        iconic waterway crafted by Ibrahim Quli Qutb Shah in 1563. This
        picturesque lake is graced by the magnificent Buddha Statue of
        Hyderabad, a renowned landmark that captivates visitors from near and
        far
      </span>
    ),
  },
  {
    imgSrc: imagePaths.lpt3,
    title: "Salar Jung Museum",
    text: (
      <span>
        Immerse yourself in the captivating world of art and history at the
        Salar Jung Museum, renowned as one of the largest art museums in the
        world. Here, you'll encounter an unparalleled collection of artifacts,
        paintings, sculptures, and antiques from diverse civilizations, offering
        a mesmerizing journey with SR Travels Vijayawada to Hyderabad Cabs
        through the passage of time.
      </span>
    ),
  },
  {
    imgSrc: imagePaths.lpt4,
    title: "Golconda Fort",
    text: (
      <span>
       Discover the grandeur of Golconda Fort, an awe
inspiring fortress perched atop a granite hill.
Constructed in the 16th century, this historic
stronghold provides great panoramic views of the
surrounding landscape while highlighting the
remarkable architectural mastery of the Qutb Shahi
dynasty
      </span>
    ),
  },
  {
    imgSrc: imagePaths.lpt5,
    title: "Chowmahalla Palace",
    text: (
      <span>
     Step into the regal past of Hyderabad City at
Chowmahalla Palace, a magnificent palace complex
that served as the seat of the Nizams of the City. As
you explore this architectural marvel, take in the
exquisite architecture, opulent interiors, and sprawling
gardens, and admire the grandeur that once graced
this historic site.
      </span>
    ),
  }, 
  {
    imgSrc: imagePaths.lpt6,
    title: "Lumbini Park and Laser Show",
    text: (
      <span>
    Experience unparalleled relaxation at Lumbini Park, a
picturesque public park nestled along the serene
Hussain Sagar Lake. Indulge in the captivating laser
show held in the evenings, showcasing a mesmerizing
display of vibrant colors and stunning effects that create
an enchanting ambiance. 
      </span>
    ),
  },
];

const InsideGuideInNavigatingVijayawada = () => {
  const [activeKeys, setActiveKeys] = useState(data.map((_, index) => index)); // Keep all toggles open

  const handleToggle = (index) => {
    setActiveKeys(
      (prevKeys) =>
        prevKeys.includes(index)
          ? prevKeys.filter((key) => key !== index) // Close the accordion if already open
          : [...prevKeys, index], // Open the accordion if it's closed
    );
  };

  const toggleIcon = (index) => {
    return activeKeys.includes(index) ? (
      <i className="fa fa-minus" aria-hidden="true"></i>
    ) : (
      <i className="fa fa-plus" aria-hidden="true"></i>
    );
  };

  return (
    <>
      <Helmet>
        <title>Best Car Travels in Guntur | SR Car Travels</title>
        <meta
          name="description"
          content="SR Car Travels is dedicated to offering a high-quality, stress-free cab service in Guntur."
        />
        <link
          rel="canonical"
          href="https://srcartravels.in/best-car-travels-in-guntur"
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:site_name" content="SR Car Travels" />
        <meta property="og:url" content="https://srcartravels.in/" />
        <meta
          property="og:title"
          content="Best Car Travels in Guntur | SR Car Travels"
        />
        <meta
          property="og:description"
          content="SR Car Travels is dedicated to offering a high-quality, stress-free cab service in Guntur."
        />
      </Helmet>
      <div className="banner-contents-container">
        <img
          src={imagePaths.bannerImage}
          className="banner-backdrop d-block w-100"
          loading="lazy"
          alt="Banner"
        />
      </div>
      <div className="container-fluid">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <LazyLoadImage
                src={imagePaths.lp1}
                alt="Car Rentals in Vijayawada"
                effect="blur"
                width="100%"
                height="auto"
                style={{ borderRadius: "25px" }}
                className="img-fluid"
              />
            </div>

            <div className="col-md-7 my-5 py-5 px-5">
              <h2 class="fw-bold">
                Exploring the Scenic Route From
                <br />
                Vijayawada to Hyderabad With SR Cabs Services
              </h2>
              <p className="fs-6 ">
                Embark on a pristine journey from Vijayawada to Hyderabad with
                <a href="https://srcartravels.in/">SR Car Travels</a>, where every mile is a scenic delight waiting to
                be discovered. As you leave the bustling cityscape of Vijayawada
                behind, you'll find yourself immersed in the breathtaking beauty
                of the landscape that unfolds along the route. From lush green
                fields to majestic hills, each turn of the road offers a new
                vista to marvel at
              </p>
              <p className="fs-6">
                With our comfortable and reliable cab service, you can sit back,
                calm down, and soak in the splendor of the journey. Whether
                you're a nature enthusiast, a history buff, or simply seeking a
                tranquil escape, the scenic route to Hyderabad promises an
                unforgettable adventure filled with aweinspiring sights and
                memorable moments. Let SR Travels’Vijayawada to Hyderabad Cabs
                be your trusted companion as you explore the wonders along the
                road
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="container-fluid px-5 my-5"
        style={{
          backgroundColor: "#113042",
          color: "#fff",
          paddingTop: "60px",
          paddingBottom: "60px",
        }}
      >
        <div className="row align-items-center">
          <div className="col-md-7 my-2 p-5">
            <h3 className="text-left py-3 fs-3 fw-bold">
              Unveiling Hyderabad: Immersive Sightseeing & Seamless Travel with
              SR Cabs
            </h3>
            <p>
              Discover Hyderabad's rich heritage, vibrant culture, and modern
              marvels with our meticulously tailored sightseeing packages.
              Immerse yourself in the city's iconic landmarks, historical
              monuments, and hidden gems while enjoying the convenience and
              comfort of  <a href="https://srcartravels.in/cab-services-in-vijayawada">SR Cabs Services</a>.
            </p>
          </div>

          <div className="col-md-5 ">
            <LazyLoadImage
              src={imagePaths.lp2}
              alt="Vijayawada Road Trip Ideas"
              effect="blur"
              width="100%"
              height="auto"
              style={{ borderRadius: "25px" }}
              className="img-fluid"
            />
          </div>
        </div>
      </div>

      <div className="container py-4">
        <h2 className="mb-4 text-center fs-3 fw-bold">
          Sightseeing Places in Hyderabad
        </h2>
        <div className="row row-cols-1 row-cols-md-3 g-4">
          {bookingOptions.map((option, index) => (
            <div key={index} className="col">
              <div className="card h-100 border-0">
                <LazyLoadImage
                  src={option.imgSrc} // Now using the correct image for each option
                  alt={option.title}
                  effect="blur"
                  className="card-img px-2"
                />
                <div className="card-body text-left">
                  <h3 className="card-title fs-5">{option.title}</h3>
                  <p className="card-text fs-6">{option.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <p className="text-center px-5 fw-bold pt-4">These captivating sightseeing destinations in Hyderabad are just a glimpse of what the city has to offer. Immerse yourself in the rich history, diverse culture, & breathtaking natural landscapes with SR Vijayawada to Hyderabad Cabs services for an unforgettable experience during your visit to the city</p>
      </div>




      {/* Thin line after Outstation Services Section */}
      <div className="container pt-5">
        <div className="container-fluid">
          <div className="container ">
            <div className="row align-items-center ">
              {/* Image column */}
              <div className="col-md-5 ">
                <LazyLoadImage
                  src={imagePaths.lp3}
                  alt="Car Rentals in Vijayawada"
                  effect="blur"
                  width="100%"
                  height="auto"
                  style={{ borderRadius: "25px" }}
                  className="img-fluid "
                />
              </div>

              {/* Content column */}
              <div className="col-md-7 my-2 p-5">
                <h3 className="fs-3 fw-bold">
                  Book Your Outstation Cab Instantly with SR Car Travels
                </h3>
                <p className="fs-6">
                  To book your cab, simply visit the official <a href="https://srcartravels.in/login">SR Car travel
                  portal</a> and fill in all the relevant details, including your
                  destination and departure date. You'll then have access to a
                  list of available.
                </p>

                <p className="fs-6">
                  Vijayawada to Hyderabad cabs for your journey. Additionally,
                  you can book a one way or round trip for your <a href="https://srcartravels.in/taxi-from-vijayawada-to-hyderabad">Vijayawada to
                  Hyderabad taxi</a>. For a seamless experience, you can also reach
                  out to SR Travels Vijayawada to Hyderabad Cabs customer
                  service
                </p>

                <p className="fs-6">
                  Booking a Vijayawada Hyderabad cab is simple: log in at SR Car
                  Travels official portal and provide your details for taxi
                  booking; subsequently, view various car options at different
                  prices based on availability on specific travel dates ensuring
                  you enjoy smooth travels ahead!
                </p>

                <div class="d-flex align-items-center ">
                  <a href="tel:+918886556632" class="me-3 text-dark">
                    <i class="bi bi-telephone-fill"></i>&nbsp;+91-8886556632
                  </a>
                  <a href="mailto:contact@srcartravels.in" class="text-dark">
                    <i class="bi bi-envelope-fill"></i>
                    &nbsp;contact@srcartravels.in
                  </a>
                </div>

                <br />
                <div>
                  <a
                    className="btn btn-info text-white bg-dark "
                    style={{ borderRadius: "20px", border: "none" }}
                  >
                    Know More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2 className="text-center fw-bold fs-2 py-5 mt-5">FAQ</h2>

        {/* Accordion Section */}
        <div className="accordion px-5 mx-5">
          {data.map((item, index) => (
            <div key={index} class="py-2">
              <button
                className="accordion-button fs-4 text-dark"
                type="button"
                onClick={() => handleToggle(index)}
                aria-expanded={activeKeys.includes(index) ? "true" : "false"}
                aria-controls={`collapse${index}`}
              >
                <span className="accordion-title">{item.title}</span>
                <span className="accordion-icon">{toggleIcon(index)}</span>
              </button>
              {activeKeys.includes(index) && (
                <div className="accordion-content">{item.content}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default InsideGuideInNavigatingVijayawada;
