import React from 'react';
import SRHeader from '../components/common/SRHeader';
import SRCallNowButton from '../components/common/SRCallNowButton';
import SrCarTravelsMap from '../components/common/SrCarTravelsMap';
import SRFooter from '../components/common/SRFooter';
import SRCustomForm from '../components/common/SRCustomForm';

const imagePaths = [
    require("../assets/img/Car Travels Vijayawada_Banner.png"),
    require("../assets/img/Best Car Travels in Vijayawada_Banner.png"),
    require("../assets/img/Car Rentals Vijayawada_Banner.png"),  
    require("../assets/img/Cars for Rent Vijayawada_Banner.png"),  
    require("../assets/img/Car Travellers Near Me_Banner.png"),  
    require("../assets/img/Vijayawada to Hyderabad Cabs_Banner.png"),
    require("../assets/img/Cabs from Vijayawada to Hyderabad_Banner.png"),
    require("../assets/img/Hyderabad to Vijayawada Cab Booking_Banner.png"),
    require("../assets/img/Taxi from Hyderabad to Vijayawada_Banner.png"),
];


const LayoutKeyWordPages = ({ children, BannerTitle, BannerImgIndex }) => {
    return (
        <>
            <SRHeader /> 
            <SRCustomForm BannerTitle={BannerTitle} BannerImg={imagePaths[BannerImgIndex]}/>
            {children}
            <SrCarTravelsMap />
            <SRFooter />
        </>
    );
};

export default LayoutKeyWordPages;
