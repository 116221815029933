import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from "react-helmet";
import "../LandingPages/InsideGuideInNavigatingVijayawada.css";

// Assuming these paths are correct relative to this component file
const imagePaths = {
  bannerImage: require("../assets/img/lp1/l1.png"),
  lp1: require("../assets/img/lp7-1.png"),
  lp2: require("../assets/img/lp7-2.png"),
  lp3: require("../assets/img/lp7-3.png"),
  lp4: require("../assets/img/lp7-4.png"),
  lp5: require("../assets/img/lp7-5.png"),
  lpt1: require("../assets/img/lpt7-1.png"),
  lpt2: require("../assets/img/lpt7-2.png"),
  lpt3: require("../assets/img/lpt7-3.png"),
  lpt4: require("../assets/img/lpt7-4.png"), 
  lpt5: require("../assets/img/lpt7-5.png"), 
  lpt6: require("../assets/img/lpt7-6.png"), 
};

// Custom style for the thin line
const thinLineStyle = {
  width: "75%",
  margin: "20px auto", // Combined marginTop and marginBottom
  height: "1px",
  backgroundColor: "#ddd",
};

const data = [
  {
    title:
      "Are your vehicles equipped with GPS navigation systems?",
    content:
      "All of our vehicles come equipped with GPS navigation systems to guarantee precise route planning and punctual arrivals. Our drivers are well-versed in the routes between Hyderabad and Vijayawada, ensuring a seamless and stress-free journey.",
  },
  {
    title: "What happens if I change my travel plans after booking a cab?",
    content:
      "If you need to modify your travel plans, such as adjusting the pick up time or location, kindly contact our customer service team as soon as possible. We will try to fulfil your requests based on vehicle and driver availability.",
  },
  {
    title: "Do you assist with luggage handling?",
    content:
      "Rest assured, our drivers undergo thorough training to assist with luggage handling. Whether traveling with heavy bags or bulky items, our capable team is dedicated to ensuring your belongings are safely stowed and unloaded at your destination",
  },
  {
    title: "Do you provide child seats for young passengers traveling from Hyderabad to Vijayawada?",
    content:
    "We understand the importance of young passengers' safety and comfort, so we offer child seats uponrequest. Kindly specify the number of child seats needed when booking to ensure a pleasant journey for your little ones.",
  },
  {
    title:
      "What languages do your drivers speak?",
    content:
      "Our drivers are highly proficient in English and the local languages, guaranteeing seamless communication and a pleasant experience for all passengers.",
  },
];

const bookingOptions = [
  {
    imgSrc: imagePaths.lp2,
    title: "Customized Travel Solutions",
    text: (
      <>
       We understand the unique nature of every business
trip. That's why we offer tailor-made travel packages to
streamline your journey and boost your productivity.
Whether you're heading to crucial meetings,
conferences, or corporate events, our committed team
is committed to delivering the highest level of service
and comfort. We specialize in flexible scheduling
options and work closely with you to design a
personalized travel experience that seamlessly aligns
with your business objectives. 
      </>
    ),
  },
  {
    imgSrc: imagePaths.lp3,
    title: "Luxury Cab Solutions",
    text: (
      <span>
       Elevate your business travel experience with SR Car
Travels Taxi from Hyderabad to Vijayawada’s tailored
solutions to meet your professional needs. We provide
a premium vehicle fleet with the latest amenities and
technology. Our team of skilled and experienced
drivers ensures punctuality and discretion,
guaranteeing a seamless and stress-free travel
experience. At SR Car Travels, we uphold the highest
standards of luxury and convenience for all your
business travel requirements.
      </span>
    ),
  },
  {
    imgSrc: imagePaths.lp4,
    title: "Airport Transfers",
    text: (
      <span>
    Experience the convenience and reliability of stress-free
airport transfers with SR Car Travels. Our dedicated
service is backed by experienced and professional
drivers committed to ensuring your timely arrival at the
airport. This allows you to prioritize your business
agenda without any hassle or delays. Count on our
punctual and efficient airport transfer service for a
smooth journey to or from the airport, providing you with
the peace of mind needed for a successful business
trip. 
      </span>
    ),
  } 
];

const InsideGuideInNavigatingVijayawada = () => {
  const [activeKeys, setActiveKeys] = useState(data.map((_, index) => index)); // Keep all toggles open

  const handleToggle = (index) => {
    setActiveKeys(
      (prevKeys) =>
        prevKeys.includes(index)
          ? prevKeys.filter((key) => key !== index) // Close the accordion if already open
          : [...prevKeys, index], // Open the accordion if it's closed
    );
  };

  const toggleIcon = (index) => {
    return activeKeys.includes(index) ? (
      <i className="fa fa-minus" aria-hidden="true"></i>
    ) : (
      <i className="fa fa-plus" aria-hidden="true"></i>
    );
  };

  return (
    <>
      <Helmet>
        <title>Best Car Travels in Guntur | SR Car Travels</title>
        <meta
          name="description"
          content="SR Car Travels is dedicated to offering a high-quality, stress-free cab service in Guntur."
        />
        <link
          rel="canonical"
          href="https://srcartravels.in/best-car-travels-in-guntur"
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:site_name" content="SR Car Travels" />
        <meta property="og:url" content="https://srcartravels.in/" />
        <meta
          property="og:title"
          content="Best Car Travels in Guntur | SR Car Travels"
        />
        <meta
          property="og:description"
          content="SR Car Travels is dedicated to offering a high-quality, stress-free cab service in Guntur."
        />
      </Helmet>
      <div className="banner-contents-container">
        <img
          src={imagePaths.bannerImage}
          className="banner-backdrop d-block w-100"
          loading="lazy"
          alt="Banner"
        />
      </div>
      <div className="container-fluid">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <LazyLoadImage
                src={imagePaths.lp1}
                alt="Car Rentals in Vijayawada"
                effect="blur"
                width="100%"
                height="auto"
                style={{ borderRadius: "25px" }}
                className="img-fluid"
              />
            </div>

            <div className="col-md-7 my-5 py-5 px-5">
              <h2 class="fw-bold">
              Get Down to Business Faster with SR
Car Travels’ Taxi From Hyderabad to
Vijayawada
              </h2>
              <p className="fs-6 ">
              Welcome to the ultimate solution for your Hyderabad to Vijayawada travel needs - the renowned <a href="https://srcartravels.in/login">SR
Car Travels</a>. We understand that your time is precious, especially during business trips, and that's
why we are committed to providing a smooth and efficient journey. With our luxurious taxi from
Hyderabad to Vijayawada, you can bid farewell to transportation concerns and concentrate on what
truly matters – your business objectives. 
              </p>
              <p className="fs-6">
              Our commitment to reliability ensures that you arrive at your destination promptly and in style,
enabling you to maximize every minute of your trip. Embrace the convenience, comfort, and
professionalism SR Car Travels offers as we strive to help you expedite getting to business faster
than ever before.
              </p>
            </div>
          </div>
        </div>
      </div>
 
      <div className="container py-4">
        <h2 className="mb-4 text-center fs-3 fw-bold">
        Explore Our Services
        </h2>
        <div className="row row-cols-1 row-cols-md-3 g-4">
          {bookingOptions.map((option, index) => (
            <div key={index} className="col">
              <div className="card h-100 border-0">
                <LazyLoadImage
                  src={option.imgSrc} // Now using the correct image for each option
                  alt={option.title}
                  effect="blur"
                  className="card-img px-2"
                />
                <div className="card-body text-left">
                  <h3 className="card-title fs-5">{option.title}</h3>
                  <p className="card-text fs-6">{option.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
            
        <div className="text-center pt-5">
        <a href="http://srcartravels.in" className="btn btn-info text-center text-white bg-dark "
                    style={{ borderRadius: "20px", border: "none" }}
                  >
                  Visit our website to book your next trip
                  </a>
        </div>
      </div>



      <h2 className="text-center mb-4 fw-bold fs-3 py-5">
      What Makes Us Different?
      </h2>
      <div className="container">
        {/* Outstation Services Section */}
        <div className="row justify-content-center align-items-center g-1 my-4">
          <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
              src={imagePaths.lpt1}
              alt="cab services in vijayawada: outstation"
              effect="blur"
              className="img-fluid rounded"
            />
          </div>
          <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Transparent Pricing</h3>
          </div>
          <div className="col-lg-4 mb-2">
            <p className="fs-6">
            We prioritize <a href="https://srcartravels.in/sp-offers">transparent pricing</a>, guaranteeing no hidden fees
or surprises. Our upfront pricing and billing practices are
designed to provide complete clarity regarding the costs
involved, offering peace of mind and ensuring that you know
exactly what you're paying for. 
            </p> 
          </div>
        </div>

        <hr style={thinLineStyle} />{" "}
        {/* Thin line after Outstation Services Section */}
        {/* Local Cab Services Section */}
        <div className="row justify-content-center align-items-center g-1 my-4">
          <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
              src={imagePaths.lpt2}
              alt="Local Cab Services in Vijayawada"
              effect="blur"
              className="img-fluid rounded"
            />
          </div>
          <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">High-Quality Fleet
            </h3>
          </div>
          <div className="col-lg-4 mb-2">
            <p className="fs-6">
            At SR Car Travels’ Taxi from Hyderabad to Vijayawada, we
uphold a high standard of excellence with our diverse fleet of
vehicles, catering to your every need, from standard sedans to
luxurious cars. We prioritize your comfort and style by
meticulously maintaining our vehicles, ensuring they are
impeccably clean and equipped with modern amenities.
            </p>
          </div>
        </div>
        <hr style={thinLineStyle} />{" "}

        {/* Thin line after Local Cab Services Section */}
        {/* Airport Services Section */}
        <div className="row justify-content-center align-items-center g-1 my-4">
          <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
              src={imagePaths.lpt3}
              alt="Airport Cab Services in Vijayawada"
              effect="blur"
              className="img-fluid rounded"
            />
          </div>
          <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Customized Travel Solutions</h3>
          </div>
          <div className="col-lg-4 mb-2">
            <p className="fs-6">
            We specialize in providing customized travel solutions to meet
your unique needs and preferences. Whether you're seeking
one-way, round-trip, or multi-stop itineraries, our services are
tailored to accommodate your requirements. 
            </p>
          </div>
        </div>


        <hr style={thinLineStyle} />{" "}

{/* Thin line after Local Cab Services Section */}
{/* Airport Services Section */}
<div className="row justify-content-center align-items-center g-1 my-4">
  <div className="col-lg-2 text-center mb-2">
    <LazyLoadImage
      src={imagePaths.lpt4}
      alt="Airport Cab Services in Vijayawada"
      effect="blur"
      className="img-fluid rounded"
    />
  </div>
  <div className="col-lg-4 text-left mb-2">
    <h3 className="fs-5 fw-bold">Convenience and Ease of
    Booking</h3>
  </div>
  <div className="col-lg-4 mb-2">
    <p className="fs-6">
    Our team provides a convenient and user-friendly booking
process that allows you to book your ride effortlessly. Whether
you make your reservation online, through our mobile app, or
via phone, we ensure a simple booking experience.
    </p>
  </div>
</div>


  <hr style={thinLineStyle} />{" "}
{/* Airport Services Section */}
<div className="row justify-content-center align-items-center g-1 my-4">
  <div className="col-lg-2 text-center mb-2">
    <LazyLoadImage
      src={imagePaths.lpt5}
      alt="Airport Cab Services in Vijayawada"
      effect="blur"
      className="img-fluid rounded"
    />
  </div>
  <div className="col-lg-4 text-left mb-2">
    <h3 className="fs-5 fw-bold">Flexibility & Adaptability</h3>
  </div>
  <div className="col-lg-4 mb-2">
    <p className="fs-6">
    The taxi from Hyderabad to Vijayawada services are designed
to provide flexibility and adaptability. We're equipped to
accommodate last-minute changes and adjustments, ensuring
that your travel experience aligns with your needs. 
    </p>
  </div>
</div>



<hr style={thinLineStyle} />{" "}
{/* Airport Services Section */}
<div className="row justify-content-center align-items-center g-1 my-4">
  <div className="col-lg-2 text-center mb-2">
    <LazyLoadImage
      src={imagePaths.lpt6}
      alt="Airport Cab Services in Vijayawada"
      effect="blur"
      className="img-fluid rounded"
    />
  </div>
  <div className="col-lg-4 text-left mb-2">
    <h3 className="fs-5 fw-bold">24/7 Customer Support</h3>
  </div>
  <div className="col-lg-4 mb-2">
    <p className="fs-6">
    Our committed customer support team is committed to
providing exceptional assistance around the clock, addressing
any inquiries, changes, or concerns you may have. We are here
24/7 to ensure that you always receive outstanding service
and support. 
    </p>
  </div>
</div>


      </div>


     





      {/* Thin line after Outstation Services Section */}
      <div className="container pt-5">
        <div className="container-fluid">
          <div className="container ">
            <div className="row align-items-center ">
              {/* Image column */}
              <div className="col-md-5 ">
                <LazyLoadImage
                  src={imagePaths.lp5}
                  alt="Car Rentals in Vijayawada"
                  effect="blur"
                  width="100%"
                  height="auto"
                  style={{ borderRadius: "25px" }}
                  className="img-fluid "
                />
              </div>

              {/* Content column */}
              <div className="col-md-7 my-2 p-5">
                <h3 className="fs-3 fw-bold">
                Focus on Business, Not the Drive: Choose SR Car
Travels for Your Hyderabad Vijayawada Trips
                </h3>
                <p className="fs-6">
                SR Car Travels offers a premier solution for your business travel needs from Hyderabad to
Vijayawada. With our luxurious and reliable <a href="https://srcartravels.in/taxi-services-in-vijayawada">taxi services</a>, we prioritize your time & convenience,
allowing you to focus on your business objectives without transportation worries. Our commitment
to personalized travel solutions ensures that every aspect of your journey is tailored to meet the
demands of your professional agenda
                </p>

                <p className="fs-6">
                Furthermore, our transparent pricing policy and dedication to maintaining a high-quality fleet set us
apart in delivering exceptional service. Whether it's airport transfers or accommodating last-minute
changes, our 24/7 customer support team stands ready to provide outstanding assistance at all
times.
                </p>

                <p className="fs-6">
                Experience the difference with SR Car Travels’ Taxi from Hyderabad to Vijayawada. We elevate the
standards of business travel with convenience, comfort, and professionalism
                </p>

                <div class="d-flex align-items-center ">
                  <a href="tel:+918886556632" class="me-3 text-dark">
                    <i class="bi bi-telephone-fill"></i>&nbsp;+91-8886556632
                  </a>
                  <a href="mailto:contact@srcartravels.in" class="text-dark">
                    <i class="bi bi-envelope-fill"></i>
                    &nbsp;contact@srcartravels.in
                  </a>
                </div>

                <br />
                <div>
                  <a href="https://srcartravels.in/"
                    className="btn btn-info text-white bg-dark "
                    style={{ borderRadius: "20px", border: "none" }}
                  >
                   Visit Our Website
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2 className="text-center fw-bold fs-2 py-5 mt-5">FAQ</h2>

        {/* Accordion Section */}
        <div className="accordion px-5 mx-5">
          {data.map((item, index) => (
            <div key={index} class="py-2">
              <button
                className="accordion-button fs-4 text-dark"
                type="button"
                onClick={() => handleToggle(index)}
                aria-expanded={activeKeys.includes(index) ? "true" : "false"}
                aria-controls={`collapse${index}`}
              >
                <span className="accordion-title">{item.title}</span>
                <span className="accordion-icon">{toggleIcon(index)}</span>
              </button>
              {activeKeys.includes(index) && (
                <div className="accordion-content">{item.content}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default InsideGuideInNavigatingVijayawada;
