import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet';
import SRCustomForm from '../components/common/SRCustomForm';
import FAQsComponentForTaxiFromVijToHyd from './FAQsComponentForTaxiFromVijToHyd';
import SRTravelOptionsForTFVTH from './SRTravelOptionsForTFVTH';

// Assuming these paths are correct relative to this component file
const imagePaths = [
    require("../assets/img/tfvh-1.png"),
    require("../assets/img/tfvh-5.png"),
    require("../assets/img/tfvh-6.png"),
    require("../assets/img/tfvh-7.png"),
    require("../assets/img/tfvh-8.png"),
];

const customImagePaths = {  
    bannerImage: require("../assets/img/Taxi From Vijayawada to Hyderabad_Banner.png") 
};

const TaxiFromVijayawadaToHyderabad = () => {
    return (
        <>
            <Helmet>
                <title>Taxi from Vijayawada to Hyderabad | SR Car Travels</title>
                <meta name="description" content="SR Car Travels provides a reliable and affordable taxi from Vijayawada to Hyderabad service that is essential for a stress-free journey. more details to contact@srcartravels.in" />
                <link rel="canonical" href="https://srcartravels.in/taxi-from-vijayawada-to-hyderabad" />
                <meta name="robots" content="index, follow" />

            </Helmet>
            <SRCustomForm BannerTitle="Taxi From Vijayawada to Hyderabad" BannerImg={customImagePaths.bannerImage}/>
            <br/><br/><br/>
            <div className="taxi-service-banner py-md-5 mt-md-5" style={{ display: 'flex', alignItems: 'center', backgroundColor: '#fff', borderRadius: '10px', overflow: 'hidden',   maxWidth: '1131px', margin: 'auto' }}>
                <div style={{ flex: '1' }}>
                    <LazyLoadImage
                        src={imagePaths[0]}
                        alt="Taxi Service"
                        effect="blur"
                        className="img-fluid rounded-start"
                        style={{ height: '100%', objectFit: 'cover', borderRadius: '10px 0 0 10px' }}
                    />
                </div>
                <div style={{ flex: '1', padding: '20px' }}>
                    <h1 style={{ fontSize: '2rem' }}>SR Car Travels Offers Unbeatable Prices for Taxi from Vijayawada to Hyderabad</h1>
                    <p>When it comes to planning a trip from Vijayawada to the vibrant city of Hyderabad, finding reliable and affordable transportation is essential for a stress-free experience. If you're a traveler looking for an unforgettable journey, look no further than <a href="https://srcartravels.in/login" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>SR Car Travels.</a></p>
                    <p>Our taxi from Vijayawada to Hyderabad service offers unbeatable prices on comfortable and reliable taxis, ensuring that your travel experience is both cost-effective and enjoyable.</p>
                    <p>Whether you're eager to <a href="https://srcartravels.in/outstation" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>explore Hyderabad's diverse scenes</a> or simply take in the city's rich culture, our taxi service will ensure you arrive ready to enjoy every moment.</p>
                </div>
            </div>
            <br></br>
            <SRTravelOptionsForTFVTH />
            <br/>
            <div className="taxi-booking-banner" style={{ display: 'flex', backgroundColor: '#fff', borderRadius: '10px', overflow: 'hidden',  maxWidth: '1120px', margin: 'auto' }}>
                <div style={{ flex: '1' }}>
                    <LazyLoadImage
                        src={imagePaths[1]}
                        alt="Why Book a Taxi From Vijayawada to Hyderabad"
                        effect="blur"
                        className="img-fluid rounded-start"
                        style={{ height: '100%', objectFit: 'cover', borderRadius: '10px 0 0 10px' }}
                    />
                </div>
                <div style={{ flex: '1', padding: '20px' }}>
                    <h2>Why Book a Taxi From Vijayawada to Hyderabad with SR Car Travels?</h2>
                    <ul>
                        <li>With our reliable and convenient Vijayawada to Hyderabad taxi service, you can rest assured of on-time pick-up and drop-off times as you enjoy a cozy ride with your loved ones in our well-maintained cars.</li>
                        <li>Our professional and knowledgeable drivers will serve as your exceptional companions on the road, ensuring a seamless and enjoyable journey.</li>
                        <li>You can now rest assured that hidden charges will no longer be a concern for you. Our billing system offers complete transparency, ensuring that what you see is exactly what you pay.</li>
                        <li>All drivers at SR Car Travels are required to successfully complete a thorough background check before joining our team. This ensures the safety and security of our valued customers.</li>
                    </ul>
                    <a
                        className="btn btn-primary"
                        style={{ backgroundColor: '#113042', borderColor: '#113042' }}
                        href="https://srcartravels.in/"
                    >
                        Know More
                    </a>
                </div>
            </div>
            <br/><br/>
            <div className="container py-5 d-flex justify-content-center align-items-center" style={{ backgroundColor: '#113042' }}>
                <div className="">
                    <h2 className="mb-4 fw-bold fs-4 text-white">
                        Select From Our Taxi Diverse Range
                    </h2>
                    <div className="row g-4 align-items-center">
                        <div className="col-md-6">
                            <div className="table-responsive">
                                <table className="table table-dark table-striped" style={{ height: '300px', width: '400px', overflowY: 'auto' }}>
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Taxi Type</th>
                                            <th scope="col">Capacity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Sedan (Dezire, Etios)</td>
                                            <td><span className="badge bg-success rounded-pill">4+1</span></td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>SUV Ertiga</td>
                                            <td><span className="badge bg-success rounded-pill">6+1</span></td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Innova</td>
                                            <td><span className="badge bg-success rounded-pill">7+1</span></td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <div className="col-md-6 text-center">
                            <LazyLoadImage
                                alt="Taxi Options"
                                src={imagePaths[2]}
                                effect="blur"
                                className="img-fluid rounded"
                                style={{ height: '300px', width: '400px' }}
                            />
                        </div>
                    </div>
                    <p className="fw-bold fs-4 text-white"><a href="https://srcartravels.in/sp-offers" style={{ color: 'white', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>Experience a special offer</a>:** Receive 10% cash back when you make an advance payment of 500! **</p>
                </div>
            </div>
            <br></br><br/>
            <div className="one-way-taxi-banner py-md-5" style={{ display: 'flex', backgroundColor: '#fff', borderRadius: '10px', overflow: 'hidden',   maxWidth: '1104px', margin: 'auto' }}>
                <div style={{ flex: '1', padding: '20px' }}>
                    <h2>SR Car Travels: Your One-Stop Shop for Affordable One-Way Taxis in Vijayawada</h2>
                    <p>SR Car Travels offers convenient one-way taxi from Vijayawada to Hyderabad in a cost-effective way. If you're planning a trip with an uncertain return journey and require travel for just one one-way, SR Car Travels’ <a href="https://srcartravels.in/car-rentals-vijayawada" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>one-way rental services</a> are the ideal solution that also saves you money. You can take advantage of our Vijayawada to Hyderabad one-way taxi services, offering cabs for your single journey at a reasonable price. SR Car Travels stands out as the top choice for affordable one-way taxi rentals on a reliable platform.</p>
                </div>
                <div style={{ flex: '1' }}>
                    <LazyLoadImage
                        src={imagePaths[3]}
                        alt="Businessman in Taxi"
                        effect="blur"
                        className="img-fluid rounded-end"
                        style={{ height: '100%', objectFit: 'cover', borderRadius: '0 10px 10px 0' }}
                    />
                </div>
            </div>

            <div className="taxi-rental-solution-banner py-md-5" style={{ display: 'flex', backgroundColor: '#fff', borderRadius: '10px', overflow: 'hidden',  maxWidth: '1122px', margin: 'auto' }}>
                <div style={{ flex: '1' }}>
                    <LazyLoadImage
                        src={imagePaths[4]}
                        alt="Highway Scene"
                        effect="blur"
                        className="img-fluid rounded-start"
                        style={{ height: '100%', objectFit: 'cover', borderRadius: '10px 0 0 10px' }}
                    />
                </div>
                <div style={{ flex: '1', padding: '20px' }}>
                    <h2>Your Perfect Solution for Taxi Rentals in Vijayawada with SR Car Travels</h2>
                    <p>SR Car Travels provides a reliable and affordable taxi from Vijayawada to Hyderabad service that is essential for a stress-free journey. The reasonable prices on comfortable and well-maintained taxis ensure both cost-effectiveness and an enjoyable travel experience for people looking to explore Hyderabad's diverse traditional and modern destinations.</p>
                    <p>With on-time pick-up and drop-off times, along with professional and knowledgeable drivers, SR Car Travels offers an outstanding transportation option for travelers seeking an unforgettable <a href="https://srcartravels.in/vijayawada-to-hyderabad-cabs" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>journey from Vijayawada to Hyderabad.</a></p>
                    <div className="contact-info">
                        <p><a href="tel:+918886556632" className="btn btn-primary">📞 +91-8886556632</a></p>
                        <p><a href="mailto:contact@srcartravels.in" className="btn btn-primary">📧 contact@srcartravels.in</a></p>
                    </div>


                    <a href="https://srcartravels.in/" className="btn btn-primary" style={{ backgroundColor: '#113042', borderColor: '#113042', textDecoration: 'none' }}>
                        Know More
                    </a>
                </div>
            </div>

            <FAQsComponentForTaxiFromVijToHyd />
        </>
    );
};

export default TaxiFromVijayawadaToHyderabad;
