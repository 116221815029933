import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const GunturTravelExperience = () => {
    // Assuming the image path is correct relative to this component file
    const imagePath = require("../assets/img/g_frame_1.png");

    return (
        <div className="container py-5">
            <div className="row align-items-center">

                {/* Content column */}
                <div className="col-md-8 mb-8">
                <br/><br/><br/><br/>
                    <h1 className="fs-4 fw-bold mb-3">Enjoy Reliable and Affordable Rides with SR Car Travels in Guntur</h1>
                    <p className="fs-6">Are you tired of facing unexpected price hikes at the end of your trip? It's time to put an end to the frustration and elevate your travel experience! At <a href="https://srcartravels.in/">SR Car Travels</a>, we recognize the importance of a comfortable and convenient journey. We are dedicated to offering a high-quality, stress-free cab service in Guntur, ensuring a
                    seamless travel experience for our customers</p>
                    <p className="fs-6">Whether you're heading to a business meeting or exploring the city with friends and family, we ensure a comfortable and seamless travel experience for you without any sudden price increases during your journey. Experience outstanding travel satisfaction with our best car travels in Guntur.</p>
                </div>

                {/* Image column */}
                <div className="col-md-4">
                    <LazyLoadImage
                        src={imagePath}
                        alt="SR Car Travel Guntur"
                        effect="blur"
                        width="100%"
                        height="auto"
                        style={{ maxHeight: "280px", maxWidth: "500px", borderRadius: "20px" }}
                        className="img-fluid rounded"
                    />
                </div>
            </div>
        </div>
    );
};

export default GunturTravelExperience;
