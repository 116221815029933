import React from 'react';
import { Helmet } from 'react-helmet';
import SRCustomForm from '../components/common/SRCustomForm';
import FAQsComponentForCarTravelsInGuntur from './FAQsComponentForCarTravelsInGuntur';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SRTravelOptionsForCTIG from './SRTravelOptionsForCTIG';

// Assuming these paths are correct relative to this component file
const imagePaths = [
    require("../assets/img/CTIG-1.png"),
    require("../assets/img/CTIG-8.png")
];

const customImagePaths = {  
    bannerImage: require("../assets/img/Car Travels in Guntur_Banner.png") 
};

const CarTravelsInGuntur = () => {
    return (
        <>
            <Helmet>
                <title>Car Travels in Guntur - SR Car Travels</title>
                <meta name="description" content="At SR Car Travels in Guntur, we place the highest priority on passenger comfort. Our carefully maintained fleet is designed to deliver an exceptionally comfortable journey." />
                <link rel="canonical" href="https://srcartravels.in/car-travels-in-guntur" />
                <meta name="robots" content="index, follow" />

            </Helmet>
            <SRCustomForm BannerTitle="Car Travels in Guntur"  BannerImg={customImagePaths.bannerImage}/>
            <br/><br/>
            <div className="stress-free-travel-banner my-md-5 pt-md-5" style={{ display: 'flex', backgroundColor: '#fff', borderRadius: '10px', overflow: 'hidden',  maxWidth: '1119px', margin: 'auto' }}>
                <div style={{ flex: '1' }}>
                    <LazyLoadImage
                        src={imagePaths[0]}
                        alt="Car Travels in Guntur"
                        effect="blur"
                        className="img-fluid rounded-start"
                        style={{ height: '100%', objectFit: 'cover', borderRadius: '10px 0 0 10px' }}
                    />
                </div>
                <div style={{ flex: '2', padding: '20px' }}>
                    <h1 style={{ fontSize: '2rem' }}>The Benefits of Choosing SR Car Travels in Guntur as Your Trusted Rental Car Service Provider </h1>
                    <p>Planning a trip to Guntur but concerned about transportation? <a href="https://srcartravels.in/" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>SR Car Travels</a> is here to elevate your journey, providing a trusted and dependable rental service. Big farewell to the worry of navigating unfamiliar roads, flagging down cabs, or depending on public transit. With SR Car Travels in Guntur, you can access unparalleled convenience, comfort, and flexibility throughout your travels.</p>
                    <p>With a diverse range of vehicles, SR Car Travels ensures that you'll discover the ideal vehicle to meet your needs whether you're in  Guntur for business or leisure. Our selection includes sleek sedans and spacious SUVs for family trips, catering to every requirement. This makes us the preferred choice for car rentals in Guntur.</p>
                </div>
            </div>
            <br></br>
            <SRTravelOptionsForCTIG />
            <br></br>
            <br></br>
            <div className="rental-service-section" style={{ backgroundColor: '#eef4f7', padding: '40px', borderRadius: '15px', maxWidth: '1171px', margin: 'auto' }}>
                <div className="row">
                    <div className="col-md-8">
                        <h3>More Than Just a Ride: Delightful Journeys Start with SR Car Travels</h3>
                        <p>SR Car Travels is the ultimate solution for all your transportation needs in Guntur. Our unwavering commitment to providing reliable rental services, coupled with our wide range of vehicles, guarantees unparalleled convenience, comfort, and flexibility throughout your journey. With a proven track record in business and service excellence, we are devoted to delivering exceptional car services that not only satisfy customers but also inspire them to recommend us to their neighbors, friends, and relatives. We prioritize the safety and reliability of our car rentals in Guntur while ensuring that every interaction with our valued customers reflects our dedication.
                        </p>
                        <p>Explore Guntur in Style & Comfort: Reserve Your Car Now! </p>
                        <div className="contact-info">
                            <p><a href="tel:+918886556632" className="btn btn-primary">📞 +91-8886556632</a></p>
                            <p><a href="mailto:contact@srcartravels.in" className="btn btn-primary">📧 contact@srcartravels.in</a></p>
                        </div>

                        <a href="https://srcartravels.in/" className="btn btn-primary" style={{ backgroundColor: '#113042', borderColor: '#113042', textDecoration: 'none' }}>
                            Know More
                        </a>
                        
                    </div>
                    <div className="col-md-4">
                        <LazyLoadImage
                            src={imagePaths[1]}
                            alt="SR Car Travels"
                            effect="blur"
                            className="img-fluid rounded"
                            style={{ maxWidth: '100%', height: 'auto' }}
                        />
                    </div>
                </div>
            </div>
            <br></br>
            <FAQsComponentForCarTravelsInGuntur />
        </>
    );
};

export default CarTravelsInGuntur;
